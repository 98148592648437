import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { HomeComponent } from './home/home.component';
import { ResumeComponent } from './resume/resume.component';
import { ContactComponent } from './contact/contact.component';
import { FileNotFoundComponent } from './file-not-found/file-not-found.component';

const routes: Routes = [

  { path: '' , redirectTo: '/home', pathMatch: 'full' },

  { path: 'home' , component: HomeComponent },
  { path: 'portfolio' , component: PortfolioComponent },
  { path: 'resume' , component: ResumeComponent },
  { path: 'contact' , component: ContactComponent },


    { path: '**' , component: FileNotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
