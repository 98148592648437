<nav-bar></nav-bar>

  <div class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col">
        <h1>File not found</h1>
        <h2>(404) error</h2>
      </div>

    </div>
  </div>
