<nav-bar></nav-bar>
<div class="container-fluid p-5 marginResume">
  <!-- intro -->
  <div class="lineLess">
  <div class="container ">
  <div class="row animate__animated animate__fadeInUp">
    <div class="col-xl-6 col-12">
      <h1>Cris Delli-Zotti</h1>
    </div>
    <div class="col-xl-6 col-12">
      <h6 class="text-right text-secondary"><img class="img-fluid mr-4" src="/assets/_img/logo-studioCDZ.png" alt="logo-studioCDZ"/>  <i class="fa fa-file-pdf-o" aria-hidden="true"></i> | <a class="downloadResume" href="/assets/_pdf/crisDelliZotti.pdf" target="_blank">Download Resume</a></h6>
    </div>
  </div>
  </div>
<div class="container">

  <div class="row animate__animated animate__fadeInUp">
    <div class="col-xl-8 col-12">
      <div class="media">
        <img class="mr-3 img-fluid img-thumbnail" src="/assets/_img/photo.png" alt="Cris Delli-Zotti image">
        <div class="media-body">
          <h5 class="mt-0">Front-End Developer | UX/UI Designer</h5>
          <h5>Senior Graphic Designer</h5>
          <p>Front-End developer-UX/UI designer & Senior Graphic Designer. Skilled experience in HTML5, CSS, Javascript, Front-end frameworks/Library (Bootstrap, jQuery, Angular), Wordpress, coding Programs (Visual Studio, Dreamweaver, Brackets), Illustrator, InDesign, Photoshop. Some knowledge of Premiere and After Effects.</p>
           
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-12">
      <div class="row justify-content-md-around m-1">
        <address>
          <strong>
          <i class="fa fa-briefcase text-secondary" aria-hidden="true"></i> <a href="http://www.studiocdz.com" target="_blank">www.studiocdz.com</a><br>  
          <span class="text-secondary"><i class="fa fa-phone-square" aria-hidden="true"></i> (314) 322-0234<br></span>
          <i class="fa fa-at text-secondary" aria-hidden="true"></i> <a href="mailto:crid71@gmail.com">crid71@gmail.com</a><br>
          </strong>
        </address>
      </div>
    </div>
  </div>
</div>
       <!-- work experience -->


       <div class="lineLess mt-5 mb-4 row animate__animated animate__fadeInUp">
        <div class="container">
           <h2>Experience</h2>
           </div>
         </div>
         <div class="container">
         <div class="row lineLess mt-5 mb-4 row animate__animated animate__fadeInUp">
             <div class="col-md-12">
     

               <div class="place mb-5">
                 <div class="row lineLess">
                   <div class="col-12 col-xl-6">
                     <h4 class="badge badge-secondary badge-lg">Studio CDZ</h4>
                   </div>
                   <div class="col-12 col-xl-6">
                     <h6 class="text-right"><span class="mr-5"><i class="fa fa-map-marker" aria-hidden="true"></i> St. Louis, MO </span> <i class="fa fa-calendar" aria-hidden="true"></i> 2003 - Ongoing</h6>
                   </div>
             
                 </div>
                 <h5>Front-End Developer - UX/UI Designer and Senior Graphic Designer</h5>
                 <ul>
                   <li>10 years experience in hand-coding for Websites, Multimedia and eMail blast.</li>
                   <li>20 years experience in creative design for Brochures,  Invitations,  Cover Design,  Newsletter,  Ads,  Logos,  Presentations.</li>
                   <li>Develop design concepts for Websites,  Blogs, eMail blast, Brochures, Invitations, Cover Design, Newsletter, Ads, Logos, Presentations.</li>
                   <li>Monitored projects through all phases of production and coordinate print materials with outside vendors.</li>
                 </ul>
                </div>

                <div class="place mb-5">
                 <div class="row lineLess">
                   <div class="col-12 col-xl-6">
                     <h4 class="badge badge-secondary badge-lg">Maritz</h4>
                   </div>
                   <div class="col-12 col-xl-6">
                     <h6 class="text-right"><span class="mr-5"><i class="fa fa-map-marker" aria-hidden="true"></i> St. Louis, MO </span> <i class="fa fa-calendar" aria-hidden="true"></i> June 2014 - September 2020</h6>
                   </div>
                 </div>
                 <h5>Front-End Developer - UX/UI Designer</h5>
                 <p>Creation of registration sites for incentive travel programs, conference programs, or promotional travel programs that the company facilitates and, at times, operates until post-event. </p>
                 <ul>
                   <li>Wireframe and prototype the structure of the project as well as develop the front-end of the sites.</li>
                   <li>Project includes: registration sites, websites, mobile app, RFP and eMail blast.</li>
                   <li>Art direction of the project for consistency design style.</li>
                   <li>Assisting in an agile environment the back-end team.</li>
                   <li>Develop graphic design for brochure, newsletters, logo, advertising.</li>
                 </ul>
      
                </div>
     
                <div class="place mb-5">
                 <div class="row lineLess">
                   <div class="col-12 col-xl-6">
                     <h4 class="badge badge-secondary badge-lg">Bryan Cave</h4>
                   </div>
                   <div class="col-12 col-xl-6">
                     <h6 class="text-right"><span class="mr-5"><i class="fa fa-map-marker" aria-hidden="true"></i> St. Louis, MO </span> <i class="fa fa-calendar" aria-hidden="true"></i> July 2006 - October 2013</h6>
                   </div>
                 </div>
                 <h5>Creative Services Specialist</h5>
                             <ul>
                   <li>Web support to the Marketing Department and Attorney (websites, blogs, email marketing, web content update).</li>
                   <li>Worked closely with marketing managers to develop creative designs for a wide range of printed materials and presentations.</li>
                   <li>Managed relationships with all outside printing and finishing vendors.</li>
                 </ul>
      
                </div>
     
                <div class="place mb-5">
                 <div class="row lineLess">
                   <div class="col-12 col-xl-6">
                     <h4 class="badge badge-secondary badge-lg">Studio Montage</h4>
                   </div>
                   <div class="col-12 col-xl-6">
                     <h6 class="text-right"><span class="mr-5"><i class="fa fa-map-marker" aria-hidden="true"></i> St. Louis, MO </span> <i class="fa fa-calendar" aria-hidden="true"></i> March 2004 - April 2006</h6>
                   </div>
                 </div>
                 <h5>Senior Graphic Designer</h5>
     
                 <ul>
                   <li>Created colorful cover design, newsletters and websites for educational and medical books.</li>
                   <li>Developed design concepts for brochures, newsletters, logos, posters and advertisements.</li>
                   <li>Creative retouching and  photo manipulation.</li>
                 </ul>
      
                </div>
     
              </div>
     </div>
     </div>
     <!-- end work experience -->

  <div class="lineLess mt-5 animate__animated animate__fadeInUp">
    <div class="container">
       <h2>Skills</h2>
       </div>
     </div>
     <div class="container">
      <div class="row animate__animated animate__fadeInUp">
        <div class="col-md-12 mb-3">
          <h5>Wireframing /Prototyping:</h5>

          <div class="progress mt-4 ">
            <div class="progress-bar bg-info " role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 80%"> Adobe XD</div>
          </div>

          <div class="progress mt-4 ">
            <div class="progress-bar bg-info pl-4" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: 60%"> Sketch</div>
          </div>

          <h5 class="mt-5">Coding:</h5>

          <div class="progress mt-4">
            <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 90%"> HTML</div>
          </div>

          <div class="progress mt-4">
            <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100" style="width: 95%"> CSS | SASS</div>
          </div>

          <div class="progress mt-4">
            <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 90%"> BOOTSTRAP 4</div>
          </div>

          <div class="progress mt-4">
            <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 70%"> JAVASCRIPT</div>
          </div>

          <div class="progress mt-4">
            <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style="width: 65%"> ANGULAR (SPA, directives, npm)</div>
          </div>

          <div class="progress mt-4">
            <div class="progress-bar bg-secondary" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100" style="width: 55%"> WORDPRESS | PHP</div>
          </div>

          <h5 class="mt-5">Design/Print:</h5>

          <div class="progress mt-4">
            <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 90%"> PHOTOSHOP</div>
          </div>

          <div class="progress mt-4">
            <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" style="width: 85%"> INDESIGN</div>
          </div>

          <div class="progress mt-4">
            <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" style="width: 90%"> ILLUSTRATOR</div>
          </div>

          <h5 class="mt-5">Video Editing:</h5>

          <div class="progress mt-4">
            <div class="progress-bar bg-purple" role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style="width: 65%"> PREMIERE</div>
          </div>
          <div class="progress mt-4">
            <div class="progress-bar bg-purple" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100" style="width: 55%"> AFTER EFFECTS</div>
          </div>
        </div>
      </div>
     </div>
    </div>
<!-- end skils -->

<div class="lineLess mt-5">
  <div class="container">
    <h2>Education</h2>
  </div>
</div>
  <div class="container ">
      <div class="row lineLess mt-5">
          <div class="col-12 col-xl-6">
            <h4 class="badge badge-info badge-lg">Istituto Europeo di Design</h4>
          </div>
          <div class="col-12 col-xl-6">
            <h6 class="text-right"><span class="mr-5"><i class="fa fa-map-marker" aria-hidden="true"></i> Milano, Italia </span> <i class="fa fa-calendar" aria-hidden="true"></i> 1994-1995</h6>
          </div>
        </div>
        <h5>Computer Graphic Bachelor Degree</h5>

    <div class="row lineLess mt-5">
      <div class="col-12 col-xl-6">
        <h4 class="badge badge-info badge-lg">I.T.S.O.S.</h4>
      </div>
      <div class="col-12 col-xl-6">
        <h6 class="text-right"><span class="mr-5"><i class="fa fa-map-marker" aria-hidden="true"></i> Milano, Italia </span> <i class="fa fa-calendar" aria-hidden="true"></i> 1988-1992</h6>
      </div>
    </div>
    <h5>High School Degree in Visual Communication</h5>
  </div>

  <div class="lineLess pt-3 mt-5">
    <div class="container">
      <h2>Foreign Languages </h2>
    </div>
  </div>
    <div class="container pb-5 mb-5">

        <div class="row">
            <div class="col-12">
              <h5>Comprehensive knowledge of the English language and Italian language (mother tongue)</h5>
            </div>
          </div>  
    </div>
</div> 
   
