
<!-- Modal alfa -->
<div class="modal fade" id="alfa" tabindex="-1" aria-labelledby="alfaLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="alfaLabel"> <strong>Alfa Romeo</strong> | mobile app</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-6 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobexd.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
        <div class="col-6 mt-2">
          <h3>  Click <a href="https://xd.adobe.com/view/f6f67982-f19d-4e9a-8d73-7b8077c60b38-a3a5/?fullscreen" target="_blank">here</a>  to see the prototype 
          </h3>
        </div>
    </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/_ux-ui/2020_07_Alfa_Romeo_001.jpg" class="img-fluid" alt="">
          </div>
      </div>
          </div>
  </div>
</div>
</div>
<!-- End Modal alfa -->



<!-- Modal alBas -->
<div class="modal fade" id="alBas" tabindex="-1" aria-labelledby="alBasLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="alBasLabel"> <strong>Al Basilico</strong> | mobile app</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-6 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobexd.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">

          </h3>
        </div>
        <div class="col-6 mt-2">
          <h3>  Click <a href="https://xd.adobe.com/view/72b17655-3dd7-481f-bf0b-9b9040d60043-27e2/?fullscreen" target="_blank">here</a>  to see the prototype 
          </h3>
        </div>
    </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/_ux-ui/2020_07_Al_Basilico_001.jpg" class="img-fluid" alt="">
          </div>
      </div>
          </div>
  </div>
</div>
</div>
<!-- End Modal alBas -->



<!-- Modal template -->
<div class="modal fade" id="ihgMobile2017" tabindex="-1" aria-labelledby="ihgMobile2017Label" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="ihgMobile2017Label"> <strong>IHG 2017</strong> | mobile app</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>

    </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/_ux-ui/2017_04_IHG_mobile.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/_ux-ui/2015_08_IHG_mobile.jpg" class="img-fluid" alt="">
          </div>
      </div>
          </div>
  </div>
</div>
</div>
<!-- End Modal template -->


<!-- Modal deltek -->
<div class="modal fade" id="deltek" tabindex="-1" aria-labelledby="deltekLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="deltekLabel"> <strong>Deltek</strong> | mobile app</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>

    </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/_ux-ui/2017_08_Deltek_mobile.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/_ux-ui/2015_08_deltek-mobile.jpg" class="img-fluid" alt="">
          </div>
      </div>
          </div>
  </div>
</div>
</div>
<!-- End Modal deltek -->


<!-- Modal honda -->
<div class="modal fade" id="honda" tabindex="-1" aria-labelledby="hondaLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="hondaLabel"> <strong>Honda</strong> | mobile app</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/_ux-ui/2015_12_honda.jpg" class="img-fluid" alt="">
          </div>
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/_ux-ui/2015_12_Acura.jpg" class="img-fluid" alt="">
          </div>
            </div>
          </div>
  </div>
</div>
</div>
<!-- End Modal honda -->


<!-- Modal template -->
<!-- <div class="modal fade" id="test" tabindex="-1" aria-labelledby="testLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="testLabel"> <strong>Title</strong> | mobile app</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-6 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobexd.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/angular.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeindesign.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
        <div class="col-6 mt-2">
          <h3>  Click <a href="#" target="_blank">here</a>  to see the prototype 
          </h3>
        </div>
    </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-center mb-5">
            <img src="../../assets/_img/" class="img-fluid" alt="">
          </div>
      </div>
          </div>
  </div>
</div>
</div> -->
<!-- End Modal template -->










