<!-- Toro -->
<div class="modal fade" id="maritzToro" tabindex="-1" aria-labelledby="maritzToroLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="maritzToroLabel"> <strong>Toro gold reward</strong>  | dynamic website</h1> 
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        
      <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/angular.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_01_Maritz_Toro_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_01_Maritz_Toro_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_01_Maritz_Toro_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_01_Maritz_Toro_005.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_01_Maritz_Toro_004.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Toro -->


<!-- WCSL -->
<div class="modal fade" id="wcsl" tabindex="-1" aria-labelledby="wcslLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="wcslLabel"><strong>WCSL</strong> | dynamic website</h1> 
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobexd.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/angular.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_08_wcsl_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_08_wcsl_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_08_wcsl_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_08_wcsl_004.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End WCSL -->

<!-- Modal All American -->
<div class="modal fade" id="allAm" tabindex="-1" aria-labelledby="allAmLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="allAmLabel"> <strong>All American Conference</strong> | dynamic website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/angular.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2020_06_Maritz_AllAmerican_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2020_06_Maritz_AllAmerican_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2020_06_Maritz_AllAmerican_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2020_06_Maritz_AllAmerican_004.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End All American -->

<!-- Modal IHG -->
<div class="modal fade" id="ihg" tabindex="-1" aria-labelledby="ihgLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="ihgLabel"> <strong>IHG Americas Conference</strong> | dynamic website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobexd.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/angular.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2017_04_Maritz_ihg_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2017_04_Maritz_ihg_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2017_04_Maritz_ihg_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2017_04_Maritz_ihg_004.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal IHG -->


<!-- Modal Infinity -->
<div class="modal fade" id="infinity" tabindex="-1" aria-labelledby="infinityLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="infinityLabel"> <strong>Infinity Americas Retailer Meeting</strong> | dynamic website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        
      <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_10_Maritz_infiniti_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_10_Maritz_infiniti_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_10_Maritz_infiniti_003.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal Infinity -->


<!-- Modal Rfp -->
<div class="modal fade" id="rfp" tabindex="-1" aria-labelledby="rfpLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="rfpLabel"> <strong>RFP (Request for Proposal)</strong> | landing page websites</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        
      <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2014_09_Brotherhood_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_09_Maritz_IHG.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_09_Maritz_workHuman.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2019_09_Maritz_landRover.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal Rfp -->

<!-- Modal ihg2015 -->
<div class="modal fade" id="ihg2015" tabindex="-1" aria-labelledby="ihg2015Label" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="ihg2015Label"> <strong>ihg2015 project</strong> | dynamic website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        
      <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_09_Maritz_ihg_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_09_Maritz_ihg_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_09_Maritz_ihg_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_09_Maritz_ihg_004.jpg" class="img-fluid" alt="">
    </div>

  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal ihg2015 -->


<!-- Modal Meet -->
<!-- <div class="modal fade" id="meet" tabindex="-1" aria-labelledby="meetLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="meetLabel"> <strong>meet project</strong> | dynamic website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/angular.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_04_www.meetmag.com_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_04_www.meetmag.com_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_04_www.meetmag.com_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_04_www.meetmag.com_004.jpg" class="img-fluid" alt="">
    </div>

  </div>
</div>
    </div>
  </div>
</div> -->
<!-- End Modal Meet -->


<!-- Modal Juniper -->
<div class="modal fade" id="juniper" tabindex="-1" aria-labelledby="juniperLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="juniperLabel"> <strong>Juniper project</strong> | dynamic website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobexd.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_11_Maritz_Juniper_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_11_Maritz_Juniper_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_11_Maritz_Juniper_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_11_Maritz_Juniper_004.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_11_Maritz_Juniper_005.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2015_11_Maritz_Juniper_006.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal Juniper -->


<!-- Modal Y&S -->
<div class="modal fade" id="yogaAndSpa" tabindex="-1" aria-labelledby="yogaAndSpaLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="yogaAndSpaLabel"> <strong>Yoga & Spa Magazine</strong> | wordPress website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2014_10_YogaSpaMag_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2014_10_YogaSpaMag_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2014_10_YogaSpaMag_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2014_10_YogaSpaMag_004.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal Y&S -->


<!-- Modal BC -->
<div class="modal fade" id="bc" tabindex="-1" aria-labelledby="bcLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="bcLabel"> <strong>Bryan Cave’s Data Privacy and Security Team</strong> | wordPress website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2013_012_www.dataprivacysecurityteam_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2013_012_www.dataprivacysecurityteam_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2013_012_www.dataprivacysecurityteam_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2013_012_www.dataprivacysecurityteam_004.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal BC -->


<!-- Modal Lambert -->
<div class="modal fade" id="lambert" tabindex="-1" aria-labelledby="lambertLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="lambertLabel"> <strong>Lambert-St. Louis Airport</strong> | dynamic website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2006_011_lambert_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2006_011_lambert_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2006_011_lambert_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2006_011_lambert_004.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal Lambert -->

<!-- Modal Custom emails  -->
<div class="modal fade" id="eMails" tabindex="-1" aria-labelledby="eMailsLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="eMailsLabel"> <strong>Emails</strong> | custom emails</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2013_04_Monthly_eNewsletter for Wells Fargo_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2011_02_Women's History Month Symposium_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2014_08_Starbucks_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2014_09_FDAF_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2014_09_Honda_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_front-end/2012_02_Darren Isaacs_email.jpg" class="img-fluid" alt="">
    </div>

  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal Custom emails  -->


<!-- Modal Template -->
<!-- <div class="modal fade" id="test" tabindex="-1" aria-labelledby="testLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="testLabel"> <strong>Test project</strong> | dynamic website</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobexd.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/html5.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/css3.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/bootstrap.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/angular.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeindesign.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/placeholder-modal.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/placeholder-modal.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div> -->
<!-- End Modal Template -->


