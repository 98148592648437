<nav-bar></nav-bar>

<div class="container-fluid p-5 marginPortfolio">
  <div class="row">
    <div class="col">
      <h6 class="text-right text-secondary"><img class="img-fluid mr-4" src="/assets/_img/logo-studioCDZ.png" alt="logo-studioCDZ"/> <i class="fa fa-file-pdf-o" aria-hidden="true"></i> | <a class="downloadPortfolio" href="/assets/_pdf/crisDelliZottiPortfolio.pdf" target="_blank">Download Full Portfolio</a></h6>
    </div>
  </div>
  <div class="row animate__animated animate__fadeIn ">
    <div class="col">
      <h1>Front-End Developer projects</h1>
    </div>
  </div>

<div id="fed" class="row  animate__animated animate__fadeInUp">
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="maritzToro" data-target="#maritzToro" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2019_01_Maritz_Toro_005.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="wcsl" data-target="#wcsl" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2019_08_wcsl_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="allAm" data-target="#allAm" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2020_06_Maritz_AllAmerican_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="ihg" data-target="#ihg" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2017_04_Maritz_ihg_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="infinity" data-target="#infinity" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2015_10_Maritz_infiniti_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="ihg2015" data-target="#ihg2015" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2015_09_Maritz_ihg_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="rfp" data-target="#rfp" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2014_09_Brotherhood_001.jpg" alt="">
    </a>
  </div>   
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="juniper" data-target="#juniper" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2015_11_Maritz_Juniper_002.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="yogaAndSpa" data-target="#yogaAndSpa" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2014_10_YogaSpaMag_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="bc" data-target="#bc" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2013_012_www.dataprivacysecurityteam.studiocdz.com_001.jpg" alt="">
    </a>
  </div>  
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="lambert" data-target="#lambert" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2006_011_lambert_001.jpg" alt="">
    </a>
  </div> 
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="eMails" data-target="#eMails" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_front-end/thumb-assets/2013_04_Monthly_eNewsletter for Wells Fargo_001.jpg" alt="">
    </a>
  </div>
</div>
<!-- end Web -->

<div class="row mt-5  animate__animated animate__fadeIn ">
  <div class="col">
    <h1 class="my-4">UX/UI projects</h1>
  </div>
</div>
<div id="uxui" class="row animate__animated animate__fadeInUp">
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="alfa" data-target="#alfa" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_ux-ui/thumb-assets/2020_07_Alfa_Romeo_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="alBas" data-target="#alBas" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_ux-ui/thumb-assets/2020_07_Al_Basilico_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="ihgMobile2017" data-target="#ihgMobile2017" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_ux-ui/thumb-assets/2017_04_IHG_mobile.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="deltek" data-target="#deltek" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_ux-ui/thumb-assets/2017_08_Deltek_mobile.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="honda" data-target="#honda" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_ux-ui/thumb-assets/2015_12_honda.jpg" alt="">
    </a>
  </div>
</div>

<!-- end UX/UI -->


<div class="row mt-5  animate__animated animate__fadeIn ">
  <div class="col">
    <h1 class="my-4">Print projects</h1>
  </div>
</div>

<div id="print" class="row animate__animated animate__fadeInUp">
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="cover" data-target="#cover" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_print/thumb-assets/2014_01_Edward Jones spring catalog_002.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="mag" data-target="#mag" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_print/thumb-assets/2015_03_MME_Spring_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="bro" data-target="#bro" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_print/thumb-assets/2015_04_Osage_Nation_003.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="logo" data-target="#logo" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_print/thumb-assets/2017_06_Gran Vin.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="ads" data-target="#ads" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_print/thumb-assets/2014_07_Greensfelder_ad_001.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="postcard" data-target="#postcard" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_print/thumb-assets/2007_03_Loyola_breakfast.jpg" alt="">
    </a>
  </div>
  <div class="mt-4 col-12 col-md-6 col-lg-4">
    <a href="t-shirt" data-target="#t-shirt" data-toggle="modal">  
        <img class="img-fluid thumb" src="../../assets/_img/_print/thumb-assets/2010_05_Chase Corporate Challenge t-shirt_002.jpg" alt="">
    </a>
  </div>
</div>
<!-- end Print -->

</div>

<!-- Modal Web section -->

<modal-fed></modal-fed>

<modal-uxui></modal-uxui>

<modal-print></modal-print>

<!-- End Modal web section -->