<nav-bar></nav-bar>
<section class="bgcontact animate__animated animate__fadeIn ">

    <div class="container">
      <div class="row">
      <div class="col animation__delay animate__animated animate__fadeInRight ">
      
      <div class="flip-container centered">
      <div class="flipper">
      <div class="front">         	
      </div>
      <div class="back">
      </div>
      
      </div>
      
      </div>
      </div>
      </div>
      </div>

</section>

