
<div class="vertical-nav animate__animated animate__fadeInLeft">

  <ul class="nav flex-column mb-0">
    
     <li class="nav-item">
      <a routerLinkActive="active"  class="nav-link" routerLink="/home" title="Logo">
        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-cursor" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z"/>
        </svg>
        <h6>Home</h6>
    </a>
    </li>
    <li class="nav-item">
      <a routerLinkActive="active"  class="nav-link" routerLink="/resume" title="Resume">
        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
        </svg>
        <h6>Resume</h6>

      </a>
    </li>
    <li class="nav-item">
      <a routerLinkActive="active"  class="nav-link" routerLink="/portfolio" title="Portfolio">
        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-briefcase" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6h-1v6a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-6H0v6z"/>
          <path fill-rule="evenodd" d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v2.384l-7.614 2.03a1.5 1.5 0 0 1-.772 0L0 6.884V4.5zM1.5 4a.5.5 0 0 0-.5.5v1.616l6.871 1.832a.5.5 0 0 0 .258 0L15 6.116V4.5a.5.5 0 0 0-.5-.5h-13zM5 2.5A1.5 1.5 0 0 1 6.5 1h3A1.5 1.5 0 0 1 11 2.5V3h-1v-.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5V3H5v-.5z"/>
        </svg>
        <h6>Portfolio</h6>

    </a>
    </li>
    <li class="nav-item">
      <a routerLinkActive="active"  class="nav-link" routerLink="/contact" title="Contact">
        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-envelope" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
        </svg>
        <h6>Contact</h6>

      </a>
    </li>
  </ul>
</div>


<section class="bgimage" id="home">
  <div class="container-fluid">
    <div class="row">
      <div class="col ml-Name-Title ">
        <h1 class="animate__animated animate__fadeInDown">Cris Delli-Zotti</h1>
          <h2 class="mt-4 animate__animated animate__slow animate__fadeInDown  ">Front-End Developer | UX/UI Designer</h2>
            <h2 class="animate__animated animate__slower animate__fadeInDown">Senior Graphic Designer</h2>
      </div>

    </div>
  </div>
</section>

<div class="icons">
  <img src="/assets/_img/icon-web-design.png" class="img-fluid animate__animated animate__fadeInRight"  alt="icon-web-design">
  <img src="/assets/_img/icon-interactive-design.png" class="img-fluid animate__animated animate__fadeInRight"  alt="interactive-design">
  <img src="/assets/_img/icon-print-design.png" class="img-fluid animate__animated animate__fadeInRight"  alt="icon-print-design">


</div>

