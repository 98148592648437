import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { HomeComponent } from './home/home.component';
import { ResumeComponent } from './resume/resume.component';
import { ContactComponent } from './contact/contact.component';
import { FileNotFoundComponent } from './file-not-found/file-not-found.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ModalFedComponent } from './portfolio/modal-fed/modal-fed.component';
import { ModalUxuiComponent } from './portfolio/modal-uxui/modal-uxui.component';
import { ModalPrintComponent } from './portfolio/modal-print/modal-print.component';
import { from } from 'rxjs';

@NgModule({
  declarations: [
    AppComponent,
    PortfolioComponent,
    HomeComponent,
    ResumeComponent,
    ContactComponent,
    FileNotFoundComponent,
    NavBarComponent,
    ModalFedComponent,
    ModalUxuiComponent,
    ModalPrintComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
