

<!-- Modal Cover -->
<div class="modal fade" id="cover" tabindex="-1" aria-labelledby="coverLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="coverLabel"> <strong>Vari</strong> | cover design</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeindesign.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2014_01_Edward Jones spring catalog_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2020_01_MeetMed_Missouri.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2015_04_Osage_Nation_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2015_03_MMAE.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2009_02_Employment Redundancies_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2011_11_European Lawyers Meeting Cover_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2004_10_Paramedic_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2016_03_Route 66_003.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal Cover -->

<!-- Modal mag -->
<div class="modal fade" id="mag" tabindex="-1" aria-labelledby="magLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="magLabel"> <strong>Vari</strong> | magazine design</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeindesign.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-6  text-center mb-5">
      <img src="../../assets/_img/_print/2015_03_MME_Spring_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2015_03_MME_Spring_002.jpg" class="img-fluid" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2015_03_MME_Spring_003.jpg" class="img-fluid" alt="">
    </div>
  </div>  
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2015_03_MME_Spring_004.jpg" class="img-fluid" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2015_03_MME_Spring_005.jpg" class="img-fluid" alt="">
    </div>
  </div>  
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2015_03_MME_Spring_006.jpg" class="img-fluid" alt="">
    </div>
  </div>  

  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2015_04_Osage_Nation_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2015_04_Osage_Nation_004.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2015_04_Osage_Nation_005.jpg" class="img-fluid" alt="">
    </div>
  </div>   

  <div class="row">
    <div class="col-6  text-center mb-5">
      <img src="../../assets/_img/_print/2000_02_Mobil_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2000_02_Mobil_002.jpg" class="img-fluid" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2000_02_Mobil_003.jpg" class="img-fluid" alt="">
    </div>
  </div>  

</div>
    </div>
  </div>
</div>
<!-- End Modal mag -->


<!-- Modal Bro -->
<div class="modal fade" id="bro" tabindex="-1" aria-labelledby="broLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="broLabel"> <strong>Vari</strong> | brochure design</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeindesign.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-6  text-center mb-5">
      <img src="../../assets/_img/_print/2010_09_UK Employment Group Brochure_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2010_09_UK Employment Group Brochure_002.jpg" class="img-fluid" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2010_09_UK Employment Group Brochure_003.jpg" class="img-fluid" alt="">
    </div>
  </div>  
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2010_09_UK Employment Group Brochure_004.jpg" class="img-fluid" alt="">
    </div>
  </div>  
  <div class="row">
    <div class="col-6  text-center mb-5">
      <img src="../../assets/_img/_print/2010_09_UK Employment Group Brochure_005.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2010_09_UK Employment Group Brochure_006.jpg" class="img-fluid" alt="">
    </div>
  </div>
  
  <div class="row">
    <div class="col-6  text-center mb-5">
      <img src="../../assets/_img/_print/2014_05_Greensfelder_Recruting_bro_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2014_05_Greensfelder_Recruting_bro_003.jpg" class="img-fluid" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2014_05_Greensfelder_Recruting_bro_002.jpg" class="img-fluid" alt="">
    </div>
  </div>  

  <div class="row">
    <div class="col-6  text-center mb-5">
      <img src="../../assets/_img/_print/2000_07_DiTrani_004.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2000_07_DiTrani_006.jpg" class="img-fluid" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2000_07_DiTrani_005.jpg" class="img-fluid" alt="">
    </div>
  </div>  

  <div class="row">
    <div class="col-6  text-center mb-5">
      <img src="../../assets/_img/_print/2009_02_Employment Redundancies_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2009_02_Employment Redundancies_003.jpg" class="img-fluid" alt="">
    </div>
  </div>
  <div class="row">
    <div class="col-12  text-center mb-5">
      <img src="../../assets/_img/_print/2009_02_Employment Redundancies_002.jpg" class="img-fluid" alt="">
    </div>
  </div>  
</div>
</div>
</div>
</div>
<!-- End Modal Bro -->


<!-- Modal Logo -->
<div class="modal fade" id="logo" tabindex="-1" aria-labelledby="logoLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="logoLabel"> <strong>Vari</strong> | logo design</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2017_06_Gran Vin.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2006_03 Meet.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2013_06_Boone_VAlley.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2014_04_ShoeMeHomeSolutions.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2012_06_Wib logo.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2008_04 logo Rapid e_005.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2015_06_Meet_Elite_Awards.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2018_Groovy Galas.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 text-center mb-5">
      <img src="../../assets/_img/_print/2011_04 logo SwwetHearts.jpg" class="img-fluid" alt="">
    </div>

  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal Logo  -->


<!-- Modal Ads -->
<div class="modal fade" id="ads" tabindex="-1" aria-labelledby="adsLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="adsLabel"> <strong>Vari</strong> | advertisment design</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2014_07_Greensfelder_ad_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2014_07_Greensfelder_ad_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2011_08_Melanoma Research Foundation Journal_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2008_09_Franchise Law Compliance Program_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2008_10_BLSA Dinner Ad_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 text-center mb-5">
      <img src="../../assets/_img/_print/2011_11_7th Annual Poker Event_002.jpg" class="img-fluid" alt="">
    </div>

  </div>
</div>
    </div>
  </div>
</div> 
<!-- End Modal ads -->



<!-- Modal postcard -->
<div class="modal fade" id="postcard" tabindex="-1" aria-labelledby="postcardLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="postcardLabel"> <strong>Vari</strong> | postcard design</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeindesign.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2007_03_Loyola_breakfast.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2007_03_Stanford_breakfast.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2006_01_postcard_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2006_01_postcard_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2008_02_NALP_Invitation_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2008_02_NALP_Invitation_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2007_02_NAPL_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2007_02_NAPL_002.jpg" class="img-fluid" alt="">
    </div>

  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal postcard -->


<!-- Modal T-shirt -->
<div class="modal fade" id="t-shirt" tabindex="-1" aria-labelledby="t-shirtLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="t-shirtLabel"> <strong>Vari</strong> | t-shirt design</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row px-5 py-3">
      <!-- <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div> -->        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2010_05_Chase Corporate Challenge t-shirt_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2010_05_Chase Corporate Challenge t-shirt_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2013_08_For a Greener Game_003.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-12 col-lg-6 text-center mb-5">
      <img src="../../assets/_img/_print/2003_08_RedRaiders_001.jpg" class="img-fluid" alt="">
    </div>

  </div>
</div>
    </div>
  </div>
</div>
<!-- End Modal T-shirt -->


<!-- Modal Template -->
<!-- <div class="modal fade" id="test" tabindex="-1" aria-labelledby="testLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
    <div class="row modal-header">
      <div class="col-8">
        <h1 class="modal-title" id="testLabel"> <strong>Vari</strong> | test design</h1>  
      </div>
      <div class="col-1">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <div class="lr">
            <div class="rl"></div>
          </div>
        </button>
      </div>
    </div>
    <div class="row p-5">
      <div class="col-12">
        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente minima illum quasi ullam iste corrupti suscipit, tempora quos id quae, ex, temporibus porro asperiores excepturi!</h3>  
      </div>
        <div class="col-12 mt-2">
          <h3> <strong  class="pr-3">Technical tools: </strong> 
            <img src="../../assets/_svg/adobeillustrator.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobephotoshop.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
            <img src="../../assets/_svg/adobeindesign.svg" class="img-fluid pr-2 icons-tools" alt="icons-tools">
          </h3>
        </div>
    </div>
<div class="modal-body">
  <div class="row">
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2014_01_Edward Jones spring catalog_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2020_01_MeetMed_Missouri.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2015_04_Osage_Nation_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2015_03_MMAE.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2009_02_Employment Redundancies_001.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2011_11_European Lawyers Meeting Cover_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2004_10_Paramedic_002.jpg" class="img-fluid" alt="">
    </div>
    <div class="col-6 col-lg-4 text-center mb-5">
      <img src="../../assets/_img/_print/2016_03_Route 66_003.jpg" class="img-fluid" alt="">
    </div>
  </div>
</div>
    </div>
  </div>
</div> -->
<!-- End Modal template -->