import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-print',
  templateUrl: './modal-print.component.html',
  styleUrls: ['./modal-print.component.css']
})
export class ModalPrintComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
