import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'modal-uxui',
  templateUrl: './modal-uxui.component.html',
  styleUrls: ['./modal-uxui.component.css']
})
export class ModalUxuiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
